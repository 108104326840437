
import './ArrBlocks.scss';

export const ArrBlocks = () => {

 

  return <>
    <div className="container arrBlocks">
      <div className="arrBlocks__title" id='help'>Про нас</div>
      <div className="arrBlocks__subTitle">TG Bazar - це молодий амбітний проєкт, який представляє собою бота в telegram, за допомогою якого кожен може створити власний інтернет-магазин та підвищити продаж своїх товарів.<br/><br/>Ваші товари потрапляють до загального каталогу товарів всіх магазинів, кількість замовлень збільшується, також у Вас є можливість просувати Ваш товар серед інших товарів в інших магазинах, за допомогою реклами, яку ви можете придбати всередині всіх магазинів.<br/><br/>Після створення магазину у Вас є можливість імпортувати товари з excel для швидкого старту в своїй панель управління. Дизайн та все інше повністю налаштовується під кожен магазин.<br/><br/>Якщо щось не зрозуміло технічна підримка працює цілодобово, звертайтесь в telegram @betplaneto</div>
    </div>
    <div className="arrBlocks__background"></div>
  </>;
}